import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/affordable-living-san-diego-budget");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Affordable Living in San Diego_ Buying on a Budget.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                While the sunny weather and coastal beauty make San Diego a desirable place to live,
                the city's high housing costs can make it challenging. However, if you're willing to
                be a bit flexible and strategic, there are still affordable options to be found!
              </CommonParagraph>
              <CommonParagraph>
                This blog is about buying on a budget, so let's explore San Diego's housing market,
                affordable neighborhoods, and some tips to help you secure a home in this gorgeous
                Southern California city.
              </CommonParagraph>
              <Heading type="h2">Overview of San Diego Housing Costs</Heading>
              <CommonParagraph>
                It's no secret that{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego"
                  text="purchasing a home"
                />{" "}
                in San Diego is expensive. With median prices approaching one million dollars, it
                can seem overwhelming for budget-conscious buyers, but it's important to keep in
                mind that prices vary widely. Both neighborhood and proximity to the city center can
                factor into a home's listing price.
              </CommonParagraph>
              <CommonParagraph>
                With a bit of research and careful planning, finding more affordable areas of San
                Diego is possible.
              </CommonParagraph>
              <Heading type="h2">Affordable Neighborhoods in San Diego</Heading>
              <CommonParagraph>
                While upscale areas like La Jolla and Del Mar come with high price tags, there are
                still pockets of affordability in the area, including:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>
                  <strong>Allied Gardens:</strong> Ideal for families looking for suburban living
                  without straying too far from city amenities,{" "}
                  <BlogLink
                    url="https://selbysellssd.com/san-diego-communities/allied-gardens-homes-for-sale"
                    text="Allied Gardens"
                  />{" "}
                  offers homes at more affordable prices compared to central San Diego.
                </li>
                <li>
                  <strong>City Heights:</strong> An up-and-coming, centrally located area,{" "}
                  <BlogLink
                    url="https://selbysellssd.com/san-diego-communities/city-heights-homes-for-sale"
                    text="City Heights"
                  />{" "}
                  is great for those looking to invest in a diverse community while on a budget.
                </li>
                <li>
                  <strong>La Mesa:</strong> The charming{" "}
                  <BlogLink
                    url="https://selbysellssd.com/san-diego-communities/la-mesa-homes-for-sale"
                    text="La Mesa"
                  />{" "}
                  area is a mix of vintage houses and modern builds. Its affordability makes it an
                  attractive neighborhood, especially for first-time buyers.
                </li>
                <li>
                  <strong>Lemon Grove:</strong>{" "}
                  <BlogLink
                    url="https://selbysellssd.com/san-diego-communities/lemon-grove-homes-for-sale"
                    text="Lemon Grove"
                  />{" "}
                  offers some of the most budget-friendly homes in the county and is known for its
                  warm, small-town feel.
                </li>
              </ul>
              <Heading type="h2">Tips for Buying a Home on a Budget</Heading>
              <CommonParagraph>
                While the cost of living in San Diego and navigating a competitive housing market
                can be tricky, it's still possible to secure a home within your budget. Here are
                some tips to help you secure the affordable home you're looking for.
              </CommonParagraph>
              <Heading type="h3">Be Prepared</Heading>
              <CommonParagraph>
                Before you start house hunting, know what you can afford. By getting pre-approved
                for a mortgage, you narrow your search and show sellers you're a serious buyer.
              </CommonParagraph>
              <CommonParagraph>
                Knowing the areas you'd like to explore can also help set you up for success. By
                eliminating the regions with higher price tags, you can use your time more
                efficiently, focusing on the neighborhoods with options at your price point.
              </CommonParagraph>
              <Heading type="h3">Explore Relevant Homebuyer Programs</Heading>
              <CommonParagraph>
                There are several programs and financing options available that can help you secure
                a San Diego home. For example, the San Diego Housing Commission's First-Time
                Homebuyer Program assists with down payments and closing costs for those who
                qualify.
              </CommonParagraph>
              <CommonParagraph>
                There are also many federal programs, like FHA loans, that can make it easier for
                buyers with lower credit scores or smaller down payments to get into the market. Be
                sure to explore the different programs and take advantage of the ones that apply to
                your situation.
              </CommonParagraph>
              <Heading type="h3">Don't Rule Out a Fixer-Upper</Heading>
              <CommonParagraph>
                For budget-conscious buyers, considering a fixer-upper can be a smart strategy.
                Homes that need a little bit of work often sell for less, giving you the chance to
                build equity as you make home improvements. However, during your search, don't
                forget to factor in the cost of any necessary renovations so you don't feel
                financially stretched.
              </CommonParagraph>
              <Heading type="h3">Expand Your Search Area</Heading>
              <CommonParagraph>
                Living in San Diego on a budget is possible, but it's difficult to do in prime
                neighborhoods. Consider expanding your search to nearby areas with more affordable
                options. By living in the surrounding vicinity, you'll still have access to the many
                amenities San Diego offers without paying premium prices.
              </CommonParagraph>
              <Heading type="h2">Find Your Perfect, Budget-Friendly Home</Heading>
              <CommonParagraph>
                With help from The Selby Team, San Diego homeownership is within reach, even for
                those on a budget. Let us introduce you to the affordable communities and options
                available to you today–
                <ContactSlideoutLink text="connect with us now" />!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
